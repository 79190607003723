import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import React, { Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import FooterComponent from "./footer/footer";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import "./index.css";
import "./i18n/i18n";
const { REACT_APP_FIREBASE, REACT_APP_RECAPTCHA } = process.env;
const firebaseConfig = JSON.parse(REACT_APP_FIREBASE);
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8BC34A",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: [
      "Ubuntu Light",
      "Ubuntu Regular",
      "Ubuntu Bold",
      "sans-serif",
    ].join(","),
    caption: {
      fontFamily: "Ubuntu Light",
      fontSize: 8,
      color: "#263238",
    },
    body1: {
      fontFamily: "Ubuntu Light",
      fontSize: 14,
      color: "#263238",
    },
    body2: {
      fontFamily: "Ubuntu Light",
      fontSize: 12,
      color: "#263238",
    },
    button: {
      fontFamily: "Ubuntu Regular",
      fontSize: 16,
      color: "#263238",
    },
    h1: {
      marginBottom: 8,
      fontFamily: "Ubuntu Bold",
      fontSize: 32,
      color: "#263238",
    },
    h2: {
      fontFamily: "Ubuntu Bold",
      marginBottom: 8,
      fontSize: 28,
      color: "#263238",
    },
    h3: {
      fontFamily: "Ubuntu Bold",
      marginBottom: 8,
      fontSize: 24,
      color: "#263238",
    },
    h4: {
      fontFamily: "Ubuntu Regular",
      fontSize: 20,
      color: "#263238",
    },
    subtitle1: {
      color: "#263238",
      fontSize: 18,
      fontFamily: "Ubuntu Light",
      lineHeight: '21px',
      letterSpacing: 0.5
    },
    subtitle2: {
      color: "#263238",
      fontSize: 16,
      fontFamily: "Ubuntu Light",
      lineHeight: '21px',
      letterSpacing: 'normal'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: "inherit",
      },
      sizeLarge: {
        width: '217px'
      }
    },
  },
});

const Home = lazy(() => import("./routes/home/home"));
const About = lazy(() => import("./routes/about/about"));
const Enterprises = lazy(() => import("./routes/enterprises/enterprises"));
const Contact = lazy(() => import("./routes/contact/contact"));
const Privacy = lazy(() => import("./routes/privacy/privacy"));

class Index extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={REACT_APP_RECAPTCHA}
        language="es"
        useRecaptchaNet={false}
        useEnterprise={false}
        scriptProps={{
          async: true, 
          defer: false, 
          appendTo: "body",
          nonce: undefined 
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("iqmedik")}</title>
          <meta name="description" content={t("home_description")} />
          <link rel="canonical" href="https://iqmedik.com/home"></link>
        </Helmet>
        <ThemeProvider theme={theme}>
          <div className="iq__index">
            <Router>
              <Suspense fallback={<div>{t("loading")}</div>}>
                <Switch>
                  <Route exact path="/" render={() => <Redirect to="/enterprises" />} />
                </Switch>
                <Switch>
                  <Route exact path="/home" component={Home} />
                </Switch>
                <Switch>
                  <Route exact path="/about" component={About} />
                </Switch>
                <Switch>
                  <Route exact path="/enterprises" component={Enterprises} />
                </Switch>
                <Switch>
                  <Route exact path="/contact" component={Contact} />
                </Switch>
                <Switch>
                  <Route exact path="/privacy-policy" component={Privacy} />
                </Switch>
              </Suspense>
              <FooterComponent />
            </Router>
          </div>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    );
  }
}

const IndexTranslate = withTranslation()(Index)

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<IndexTranslate/>, rootElement);
} else {
  ReactDOM.render(<IndexTranslate/>, rootElement);
}
