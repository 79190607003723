import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from "react-i18next";
import './footer.css';
import { Link } from 'react-router-dom';

const FooterComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="iq__footer">
            {/* <div className="iq__footer__social">
                <IconButton size="small">
                    <FacebookIcon/>
                </IconButton>
                <IconButton size="small">
                    <InstagramIcon/>
                </IconButton>
                <IconButton size="small">
                    <LinkedinIcon/>
                </IconButton>
                <IconButton size="small">
                    <TwitterIcon/>
                </IconButton>
            </div> */}
            <div className="iq__footer__sections">
                <Link to="/privacy-policy">
                    <Button size="small">
                        {t("footer_privacy")}
                    </Button>
                </Link>                
                <Divider orientation="vertical" />
                <Link to="/contact">
                    <Button size="small">
                        {t("footer_contact")}
                    </Button>
                </Link>
            </div>
            <div>
                <div className="iq__footer__rights">
                    <p className="iq__footer__caption">
                        {t("footer_rights")}
                    </p>
                </div>
                <div className="iq__footer__warning">
                    <p className="iq__footer__caption">{t("footer_warning")}</p>
                </div>
            </div>
        </div>
    )
}

export default FooterComponent;